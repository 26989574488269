import React from 'react';

import Tooltip from '@material-ui/core/Tooltip';

const Abbr = ({ children, Component = 'span', componentProps = {}, ...props }) => (
  <Tooltip {...props}>
    <Component style={{ textDecoration: 'underline dotted' }} {...componentProps}>
      {children}
    </Component>
  </Tooltip>
);

export default Abbr;
