import React from 'react';

import Typography from '@material-ui/core/Typography';

import Layout from '../../components/layout';
import SEO from '../../components/seo';
import Gallery from '../../components/Gallery';
import GalleryIntro from '../../components/GalleryIntro';
import Abbr from '../../components/Abbr';

const SecondPage = () => (
  <Layout noheader>
    <SEO title="Marie & Benjamin" />

    <Typography variant="h2" component="h1" gutterBottom>
      Marie & Benjamin
    </Typography>

    <Typography paragraph>
      C'était le 15 juin 2019 à <Abbr title="Lieu-dit Barrachin, Lectoure">Barrachin</Abbr>,
      mais aussi à <Abbr title="Belleville-en-Beaujolais">Belleville</Abbr> le 28 juillet.
    </Typography>

    <Typography paragraph>
      Ces moments, c'est avec vous que nous avons souhaité les partager. Que vous ayez pu être
      présents ou non à l'une ou l'autre de ces journées, nous voulions à nouveau
      {' '}
      <strong>vous remercier</strong> pour votre attention, votre générosité, vos mots.
    </Typography>

    <Typography paragraph>
      Dès que les choses se préciseront,<br />
      c'est sur ce site que nous vous donnerons tous les détails du voyage que nous allons faire.
      {' '}
      <span role="img" aria-label="Mouton">🐑</span>
      <span role="img" aria-label="Kiwi">🥝</span>
      <span role="img" aria-label="Gecko">🦎</span>
    </Typography>

    <Typography paragraph>
      En attendant, nous vous proposons de découvrir les photos prises lors de ces journées :
    </Typography>

    <ul>
      <li>
        Les premiers albums contiennent les clichés réalisés et développés avec talent par notre
        {' '}
        <a
          title="Retrouver son travail sur rememberhappiness-photographie.fr"
          href="https://www.rememberhappiness-photographie.fr/"
        >
          fantastique photographe <strong>Émilie</strong>
        </a>.
      </li>
      <li>
        Ensuite viennent les deux albums dans lesquels vous pouvez contribuer avec vos propres
        selfies et instantanés.
      </li>
      <li>
        Et pour finir, le résultat de la 2CV-photomaton !
      </li>
    </ul>

    <Typography variant="h4" component="h2" gutterBottom>
      Les albums photos
    </Typography>

    <GalleryIntro />

    <Gallery page="marie-et-benjamin" />
  </Layout>
);

export default SecondPage;
