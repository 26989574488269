import React from 'react';

import Typography from '@material-ui/core/Typography';

import { Link } from 'gatsby';
import Button from '@material-ui/core/Button';

import DraftsIcon from '@material-ui/icons/Drafts';
import SearchIcon from '@material-ui/icons/Search';

import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelActions from '@material-ui/core/ExpansionPanelActions';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const GalleryIntro = () => (
  <ExpansionPanel style={{ marginBottom: '1em' }}>
    <ExpansionPanelSummary
      expandIcon={<ExpandMoreIcon />}
    >
      <div>
        <span role="img" aria-label="Image encadrée">🖼</span>
        <span role="img" aria-label="Flêche vers la droite">→</span>
        <span role="img" aria-label="Imprimante">🖨️️</span>
        {' '}
        Si vous souhaitez <strong>imprimer des photos</strong>,
        prenez le temps de lire ceci d'abord !
      </div>
    </ExpansionPanelSummary>
    <ExpansionPanelDetails>
      <div>
        <Typography paragraph>
          Les photographies originales étant très volumineuses, ce sont des versions réduites et
          plus légères que nous avons mises en ligne dans les différents albums.
        </Typography>
        <Typography paragraph>
          Si vous souhaitez en imprimer ou faire faire des tirages papier,
          {' '}
          <strong>ne les téléchargez pas depuis la gallerie.</strong>
        </Typography>
        <Typography paragraph>
          Envoyez-nous simplement la liste des photos, Nous vous enverrons en retour les fichiers
          en qualité suffisante pour être imprimés.
          Pour cela, il vous faut <Link to="/marie-et-benjamin/numeros-de-photos">identifier les images par leur numéro</Link>,
          et nous <a href="mailto:benjaminetmarie@barrachin.fr">envoyer par mail</a> la liste de
          celles-ci.<br />
        </Typography>
      </div>
    </ExpansionPanelDetails>
    <ExpansionPanelActions>
      <Button
        variant="outlined"
        color="primary"
        component={Link}
        to="/marie-et-benjamin/numeros-de-photos"
      >
        <SearchIcon style={{ marginRight: '.25em' }} />
        Comment trouver le numéro d'une photo ?
      </Button>
      <Button
        variant="outlined"
        color="secondary"
        href="mailto:benjaminetmarie@barrachin.fr"
      >
        <DraftsIcon style={{ marginRight: '.25em' }} />
        Nous envoyer la liste des numéros.
      </Button>

    </ExpansionPanelActions>
  </ExpansionPanel>
);

export default GalleryIntro;
